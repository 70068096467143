.AboutMainDiv{
    /* display: flex; */
    /* max-height: 90vh; */
    color: #ffff;
    margin-top: 1rem;
}

.HomeCenterDiv{
    width: 100vw;
    max-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}


.grid1{
    width:25vw;
    margin:1rem 4rem;
    height:50vh;
    border:0.1rem solid #ffff;
}

.img{
   background-color: #ffff;
   width: 30%;
    margin-left: 3%;
    height: 90vh;
    margin-top:0.2%
}

.img1{
    margin-right: -5%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

/* .dot {
    height: 30rem;
    width: 30rem;
    background-color: transparent;
    border-radius: 50%;
    border-color: red;
    border-width: 10%;
    display: inline-block;
    border:25px solid white;    
  } */


.gridElement{
    display:flex;
    justify-content:center;
    align-items:center;
}